/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';
import { isCallcenterLoggedIn, getCallcenterUser } from './callcenterService';

const baseUrl = `${process.env.BACKEND_API_BASE_URL}/jobs.php`;

const getToken = () => (isCallcenterLoggedIn() ? getCallcenterUser() : getLoginUser()).token;

export const jobsService = {
  get_jobs: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_jobs',
        token: getToken(),
        ...data, // { dbname }
      },
    }),
  get_reservation_jobs: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_reservation_jobs',
        token: getToken(),
        ...data, // { order_by, order, search, page_size, page_num, dbname }
      },
    }),
  get_routeview_jobs: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_routeview_jobs',
        token: getToken(),
        ...data, // { order_by, order, dbname, reservationDate}
      },
    }),
  get_declined_jobs: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_declined_jobs',
        token: getToken(),
        ...data, // { order_by, order, dbname }
      },
    }),
  get_job: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_job',
        token: getToken(),
        ...data, // { job_id, dbname }
      },
    }),
  get_job_payments: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'get_job_payments',
        token: getToken(),
        ...data, // { job_id, dbname }
      },
    }),
  save_job: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'save_job',
        token: getToken(),
        ...data, // { job_id, is_dispatch, job_info, dbname }
      },
    }),
  save_job_step: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'save_job_step',
        token: getToken(),
        ...data, // { job_id, step, job_info, dbname }
      },
    }),
  get_cancel_reason: async data =>
    axios.get(`${process.env.BACKEND_API_BASE_URL}/kinetik1.php`, {
      params: {
        method: 'getAccountDetail',
        token: getToken(),
        ...data,
      },
    }),
  cancel_job: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'cancel_job',
        token: getToken(),
        ...data,
      },
    }),
  confirm_reservation: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'confirm_reservation',
        token: getLoginUser().token,
        ...data,
      },
    }),
  assign_driver_to_job: async (jobId, driverId) =>
    axios.get(baseUrl, {
      params: {
        method: 'assign_driver_to_job',
        job_id: jobId,
        driver_id: driverId,
        token: getLoginUser().token,
      },
    }),
  change_reservation_time: async (job_id, reservation_time) =>
    axios.get(baseUrl, {
      params: {
        method: 'change_reservation_time',
        job_id,
        reservation_time,
        token: getLoginUser().token,
      },
    }),
  dispatch_driver_to_job: async (jobId, driverId, vehicleId) =>
    axios.get(baseUrl, {
      params: {
        method: 'dispatch_driver_to_job',
        job_id: jobId,
        driver_id: driverId,
        vehicle_id: vehicleId,
        token: getLoginUser().token,
      },
    }),
  admin_get_jobs_table: async (filter, order, page, pageSize) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_jobs_table',
        ...filter,
        order,
        page,
        page_size: pageSize,
        token: getLoginUser().token,
      },
    }),
  admin_attest_all: async filter =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_attest_all',
        ...filter,
        token: getLoginUser().token,
      },
    }),
  admin_get_mas: async jobId =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_mas',
        job_id: jobId,
        token: getLoginUser().token,
      },
    }),
  admin_job_attest: async jobId =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_job_attest',
        job_id: jobId,
        token: getLoginUser().token,
      },
    }),
  admin_job_correction: async data =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_job_correction',
        ...data,
        token: getLoginUser().token,
      },
    }),
  admin_resend_receipt: async (jobId, receiptEmail) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_resend_receipt',
        job_id: jobId,
        receipt_email: receiptEmail,
        token: getLoginUser().token,
      },
    }),
  admin_get_payment_detail: async job_payment_id =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_get_payment_detail',
        job_payment_id,
        token: getLoginUser().token,
      },
    }),
  admin_save_prior_auth: async (jobId, priorAuthNumber) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_save_prior_auth',
        job_id: jobId,
        prior_auth_number: priorAuthNumber,
        token: getLoginUser().token,
      },
    }),
  admin_set_status: async (jobId, status) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_set_status',
        job_id: jobId,
        status,
        token: getLoginUser().token,
      },
    }),
  admin_edit_fare: async (jobId, data) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_edit_fare',
        job_id: jobId,
        ...data,
        token: getLoginUser().token,
      },
    }),
  job_process_payment: async (jobId, amount, token = null) =>
    axios.get(baseUrl, {
      params: {
        method: 'job_process_payment',
        job_id: jobId,
        amount,
        token: token || getLoginUser().token,
      },
    }),
  job_charge_booking_deposit: async (jobId, token = null) =>
    axios.get(baseUrl, {
      params: {
        method: 'job_charge_booking_deposit',
        job_id: jobId,
        token: token || getLoginUser().token,
      },
    }),
  admin_mas_sync_import: async () =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_mas_sync_import',
        token: getLoginUser().token,
      },
    }),
  admin_export_jobs_table: async (filter, order, export_type) =>
    axios.get(baseUrl, {
      params: {
        method: 'admin_export_jobs_table',
        ...filter,
        order,
        export_type,
        token: getLoginUser().token,
      },
      responseType: 'blob',
    }),
};
