import axios from 'axios';
import { navigate } from 'gatsby';

const base_url = `${process.env.BACKEND_API_BASE_URL}/reservation.php`;

export const isBrowser = () => typeof window !== 'undefined';

export const getLoggedInUser = (toolType = 'passenger') =>
  typeof window !== 'undefined' &&
  window.localStorage.getItem(toolType === 'account' ? 'PergoReservationAccount' : 'PergoReservationUser')
    ? JSON.parse(
        window.localStorage.getItem(toolType === 'account' ? 'PergoReservationAccount' : 'PergoReservationUser')
      )
    : false;

export const setLoggedInUser = (user, toolType = 'passenger') => {
  window.localStorage.setItem(
    toolType === 'account' ? 'PergoReservationAccount' : 'PergoReservationUser',
    JSON.stringify(user)
  );
};

export const isLoggedIn = (toolType = 'passenger') => {
  const user = getLoggedInUser(toolType);
  return !!user.login_token;
};

export const logout = (baseToken, toolType = 'passenger') => {
  setLoggedInUser({}, toolType);
  navigate(`/reservation?token=${baseToken}&type=${toolType}`);
};

export const reservationService = {
  generate_base_token: async base =>
    axios.get(base_url, {
      params: {
        method: 'generate_base_token',
        base,
      },
    }),
  get_base_setting: async baseToken =>
    axios.get(base_url, {
      params: {
        method: 'get_base_setting',
        base_token: baseToken,
      },
    }),
  login_token_check: async (toolType = 'passenger') =>
    axios.get(base_url, {
      params: {
        method: 'login_token_check',
        login_token: getLoggedInUser(toolType).login_token,
        tool_type: toolType,
      },
    }),
  account_send_login_code: async ({ email, baseToken }) =>
    axios.get(base_url, {
      params: {
        method: 'account_send_login_code',
        email,
        base_token: baseToken,
      },
    }),
  account_login: async ({ email, baseToken, verifyCode }) =>
    axios.get(base_url, {
      params: {
        method: 'account_login',
        email,
        verify_code: verifyCode,
        base_token: baseToken,
      },
    }),
  passenger_login: async ({ phone, baseToken }) =>
    axios.get(base_url, {
      params: {
        method: 'passenger_login',
        phone,
        base_token: baseToken,
      },
    }),
  passenger_info_update: async data =>
    axios.get(base_url, {
      params: {
        method: 'passenger_info_update',
        ...data,
        login_token: getLoggedInUser().login_token,
      },
    }),
  book_reservation: async (jobInfo, toolType = 'passenger') =>
    axios.get(base_url, {
      params: {
        method: 'book_reservation',
        job_info: JSON.stringify(jobInfo),
        tool_type: toolType,
        login_token: getLoggedInUser(toolType).login_token,
      },
    }),
  get_reservations: async (filter, toolType) =>
    axios.get(base_url, {
      params: {
        method: 'get_reservations',
        ...filter,
        tool_type: toolType,
        login_token: getLoggedInUser(toolType).login_token,
      },
    }),
};
