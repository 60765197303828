import React from 'react';
import InvoicePage from 'components/Pages/invoice';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import NotificationOverlay from 'components/NotificationOverlay';

const InvoiceTemplate = (props) => (
  <CircularProgressOverlay>
    <NotificationOverlay>
      <InvoicePage {...props} />
    </NotificationOverlay>
  </CircularProgressOverlay>
);

export default InvoiceTemplate;
