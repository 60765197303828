import React, { useState, useContext, useEffect } from 'react';
import queryString from 'query-string';
import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { NotificationContext } from 'components/NotificationOverlay';

import { clearCurrency, phoneFormat, creditCardType } from 'utils/text';
import { jobsService } from 'services/jobsService';
import { reservationService } from 'services/reservationService';

import AddPaymentModal from '../../templates/manage/Passengers/AddPaymentModal';
import { PergoLogoWhite } from '../../images';

const moment = require('moment-timezone');

export default function InvoicePage(props) {
  const parsed = queryString.parse(props.location.search);
  const [addPaymentOpen, setAddPaymentOpen] = useState(null);
  const [jobDetail, setJobDetail] = useState(null);
  const [baseSetting, setBaseSetting] = useState(null);
  const [logoSrc, setLogoSrc] = useState(null);
  const [linkError, setLinkError] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { showNotification } = useContext(NotificationContext);

  const initialize = async () => {
    showCircularProgress('Loading...');
    const data = await jobsService.get_job({ token: parsed.token, job_id: parsed.job_id });
    if (data && data?.data?.status === 'SUCCESS') {
      setJobDetail(data.data.data.data);
    } else {
      setLinkError(true);
    }
    const data1 = await reservationService.get_base_setting(parsed.token);
    if (data1 && data1?.data?.status === 'SUCCESS') {
      setBaseSetting({ ...data1.data.data });
      setLogoSrc(`https://dispatch.pergosolutions.com/ehail/images/${data1.data.data.dbname}.png`);
    } else {
      setLinkError(true);
    }
    hideCircularProgress();
  };

  useEffect(() => {
    if (parsed.token && parsed.job_id) {
      initialize();
    }
  }, []);

  const handleCardOnFile = () => {
    setAddPaymentOpen(jobDetail.passenger.token ? 'Update' : 'Add');
  };

  const handleRefreshDetail = () => {
    initialize();
  };

  if (!jobDetail || !baseSetting) {
    return linkError ? <h3 style={{ padding: 20 }}>Sorry, Wrong Link</h3> : null;
  }

  const totalFare = parseFloat(jobDetail.fare) + parseFloat(jobDetail.tip);
  const paidAmount = jobDetail?.payments?.reduce((acc, cur) => acc + parseFloat(cur.amount), 0) || 0;
  const bookingDeposit = parseFloat(jobDetail.booking_deposit_amount || 0);
  const bdBalance = parseFloat(bookingDeposit) - parseFloat(paidAmount);
  const invoiceStatus = bookingDeposit > 0 ? (bdBalance > 0 ? 'pending' : 'paid') : 'none';

  const handlePayNow = async () => {
    showCircularProgress('Processing...');
    const data = await jobsService.job_charge_booking_deposit(jobDetail.id, parsed.token);
    hideCircularProgress();
    if (data && data?.data?.status === 'SUCCESS') {
      showNotification('Successfully paid', 'success');
      handleRefreshDetail();
    } else {
      showNotification(`Sorry, Error occurred: ${data?.data?.data?.code || 'Internal error'}`, 'error');
    }
  };

  return (
    <SnackbarProvider>
      <AppContainer>
        <div className="invoice-body-container" id="invoice_body_container">
          {invoiceStatus != 'none' && (
            <div className="invoice-ribbon">
              <div className={`ribbon-inner ribbon-${invoiceStatus}`}>{invoiceStatus}</div>
            </div>
          )}
          <div className="invoice-header">
            <div className="left-side">
              {logoSrc && <img src={logoSrc} onError={() => setLogoSrc(PergoLogoWhite)} alt="logo" />}
              <span>
                <b>{baseSetting.base_name}</b>
                <br />
                {baseSetting.addr1}
                <br />
                {`${baseSetting.city}, ${baseSetting.state}, ${baseSetting.zip}`}
                <br />
                U.S.A
              </span>
            </div>
            <div className="right-side">
              <span style={{ fontSize: 38 }}>Invoice</span>
              <span style={{ fontSize: 14 }}># INV-{jobDetail.id}</span>
              <span style={{ fontSize: 12, marginTop: 20 }}>
                Balance Due
                <br />
                <b style={{ fontSize: 16 }}>{clearCurrency(bdBalance)}</b>
              </span>
            </div>
          </div>
          <div className="invoice-information">
            <div className="left-side">
              <label style={{ fontSize: 14, marginBottom: 7 }}>
                <b>Bill To</b>
              </label>
              <span style={{ fontSize: 12, lineHeight: '20px' }}>
                <b>{`${jobDetail.passenger.fname} ${jobDetail.passenger.lname}`}</b>
                <br />
                {phoneFormat(jobDetail.passenger.phone)}
                <br />
                {jobDetail.passenger.email}
              </span>
            </div>
            <div className="right-side">
              <div>
                <label>Invoice Date :</label>
                <span>
                  {moment
                    .utc(jobDetail.call_time)
                    ?.tz(baseSetting.timezone)
                    ?.format('MM/DD/YYYY')}
                </span>
              </div>
              <div>
                <label>Terms :</label>
                <span>{parseInt(jobDetail.booking_deposit_percent * 100)}% of Fare</span>
              </div>
              <div>
                <label>Due Date :</label>
                <span>
                  {moment
                    .utc(jobDetail.reservation_time)
                    ?.tz(baseSetting.timezone)
                    ?.format('MM/DD/YYYY')}
                </span>
              </div>
            </div>
          </div>
          <table className="invoice-table">
            <thead>
              <tr>
                <td>Trip Number</td>
                <td>Description</td>
                <td style={{ textAlign: 'right' }}>Fare</td>
                <td style={{ textAlign: 'right' }}>Amount Due</td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: '1px solid #adadad' }}>
                <td>#{jobDetail.id}</td>
                {jobDetail?.fare_calc_type == '0' ? (
                  <td>
                    <p className="show-on-desktop">
                      <b>Pickup:</b>
                      <br />
                      {`${jobDetail.pickup.addr1}, ${jobDetail.pickup.city}, ${jobDetail.pickup.state}, ${jobDetail.pickup.zip}`}
                      <br />
                      <b>Dropoff:</b>
                      <br />
                      {`${jobDetail.dropoff.addr1}, ${jobDetail.dropoff.city}, ${jobDetail.dropoff.state}, ${jobDetail.dropoff.zip}`}
                    </p>
                    <p className="show-on-mobile">
                      <b>Pickup:</b>
                      <br />
                      {`${jobDetail.pickup.addr1}`}
                      <br />
                      <b>Dropoff:</b>
                      <br />
                      {`${jobDetail.dropoff.addr1}`}
                    </p>
                  </td>
                ) : (
                  <td>
                    <p className="show-on-desktop">
                      <b>By Time:</b>
                      <br />
                      {`${jobDetail.fare_duration} minutes trip`}
                    </p>
                  </td>
                )}
                <td style={{ textAlign: 'right' }}>{clearCurrency(jobDetail.fare)}</td>
                <td style={{ textAlign: 'right' }}>{clearCurrency(jobDetail.fare)}</td>
              </tr>
              <tr>
                <td colSpan={3}>Tax</td>
                <td>{clearCurrency(0)}</td>
              </tr>
              <tr>
                <td colSpan={3}>Gratuity</td>
                <td>{clearCurrency(jobDetail.tip)}</td>
              </tr>
              <tr style={{ borderBottom: '1px solid #adadad' }}>
                <td colSpan={3}>Total</td>
                <td>{clearCurrency(totalFare)}</td>
              </tr>
              <tr>
                <td colSpan={3}>BD Required ({parseInt(jobDetail.booking_deposit_percent * 100)}%)</td>
                <td>{clearCurrency(bookingDeposit)}</td>
              </tr>
              <tr>
                <td colSpan={3}>Payment Made</td>
                <td style={{ color: 'red' }}>(-) {paidAmount.toFixed(2)}</td>
              </tr>
              <tr style={{ borderBottom: '1px solid #adadad' }}>
                <td colSpan={3}>Balance Due</td>
                <td>{clearCurrency(bdBalance)}</td>
              </tr>
              <tr>
                <td colSpan={3}>Card on File</td>
                <td>
                  <Button
                    onClick={handleCardOnFile}
                    variant="outlined"
                    color="primary"
                    className="py-1 px-2 btn-transition-none"
                    style={{ fontSize: 12 }}
                  >
                    {jobDetail.passenger.token
                      ? `**** ${jobDetail.passenger.token.card_number.slice(-4)} (${creditCardType(
                          jobDetail.passenger.token.card_number
                        )})`
                      : 'No Card'}
                  </Button>
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  {bdBalance ? (
                    <Button
                      onClick={handlePayNow}
                      variant="contained"
                      color="primary"
                      className="py-1 btn-transition-none"
                      disabled={!jobDetail.passenger.token}
                    >
                      $ Pay Now ({clearCurrency(bdBalance)})
                    </Button>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="invoice-notes">
            <label style={{ fontSize: 14 }}>
              <b>Notes</b>
            </label>
            {jobDetail.payments?.length > 0 ? (
              <table className="invoice-payment-table">
                <thead>
                  <tr>
                    <td>Payment Received</td>
                    <td>Card</td>
                    <td>Amount Due</td>
                  </tr>
                </thead>
                <tbody>
                  {jobDetail.payments?.map((payment, index) => (
                    <tr key={index}>
                      <td>
                        {payment.updated_time && moment(payment.updated_time).isValid()
                          ? moment
                              .utc(payment.updated_time)
                              ?.tz(baseSetting.timezone)
                              ?.format('MM/DD/YYYY')
                          : '-'}
                      </td>
                      <td>{`**** ${payment.card_number.slice(-4)} (${creditCardType(payment.card_number)})`}</td>
                      <td>{clearCurrency(payment.amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <span style={{ fontSize: 12 }}>
                <br />
                No payments received
              </span>
            )}
          </div>
        </div>
      </AppContainer>

      <AddPaymentModal
        passengerDetail={{ ...jobDetail.passenger, baseToken: parsed.token }}
        addPaymentOpen={addPaymentOpen}
        handleAddPaymentClose={() => setAddPaymentOpen(null)}
        handleRefreshDetail={handleRefreshDetail}
      />
    </SnackbarProvider>
  );
}

const AppContainer = styled.div`
  max-width: 800px;
  margin: 30px auto;
  box-shadow: 0 0 6px #ccc;

  @media (max-width: 768px) {
    margin: 6px;
  }

  .invoice-body-container {
    padding: 70px 40px 70px 50px;
    position: relative;

    .invoice-ribbon {
      position: absolute !important;
      top: -5px;
      left: -5px;
      overflow: hidden;
      width: 96px;
      height: 94px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .ribbon-inner {
        text-align: center;
        color: #fff;
        top: 24px;
        left: -31px;
        width: 135px;
        padding: 3px;
        position: relative;
        transform: rotate(-45deg);
        text-transform: capitalize;

        &.ribbon-pending {
          background-color: #f59d00;
          border-color: #c27c00;
          padding: 5px 0;
          font-size: 11px;
        }

        &.ribbon-paid {
          background-color: #1fcd6d;
          border-color: #18a155;
          font-size: 13px;
          font-weight: 500;
        }

        &:before,
        &:after {
          content: '';
          border-top: 5px solid transparent;
          border-left: 5px solid;
          border-left-color: inherit;
          border-right: 5px solid transparent;
          border-bottom: 5px solid;
          border-bottom-color: inherit;
          position: absolute;
          top: 20px;
          transform: rotate(-45deg);
        }

        &:before {
          left: 0;
          border-left: 2px solid transparent;
          color: #2e8fda;
        }

        &:after {
          right: -3px;
          border-bottom: 3px solid transparent;
          color: #2e8fda;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 30px 13px;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }

    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
    }

    .invoice-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .left-side {
        flex: 1;
        font-size: 14px;

        span {
          font-size: 12px;

          b {
            font-size: 14px;
          }
        }

        img {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;

          @media (max-width: 768px) {
            width: 70px;
            height: 70px;
          }
        }
      }

      .right-side {
        flex: 1;
        font-weight: 500;
      }
    }

    .invoice-information {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 30px;

      .left-side {
        flex: 1;
      }
      .right-side {
        flex: 1;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 22px;

          label {
            font-size: 13px;
            flex: 1;
          }
          span {
            font-size: 12px;
            flex: 1;
          }
        }
      }
    }

    .invoice-table {
      width: 100%;
      margin-top: 20px;
      font-size: 12px;

      thead {
        tr {
          height: 32px;
          color: #ffffff;
          background-color: #3c3d3a;
          font-weight: bold;
        }
      }

      tr td {
        padding: 7px 10px;
      }

      tbody {
        tr:nth-child(n + 2) {
          td {
            text-align: right;
          }
        }

        tr:nth-child(n + 4) {
          font-weight: 600;
        }

        tr:nth-child(7) {
          td {
            background-color: #f5f4f3;
          }
        }

        tr:last-child {
          button {
            width: 200px;
            margin-top: 10px;

            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }

      p {
        margin: 0;
      }

      button {
        white-space: nowrap;

        @media (max-width: 768px) {
          white-space: normal;
        }
      }

      .show-on-desktop {
        display: block;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .show-on-mobile {
        display: none;

        @media (max-width: 768px) {
          display: block;
        }
      }
    }

    .invoice-notes {
      margin-top: 50px;

      .invoice-payment-table {
        font-size: 12px;
        margin-top: 20px;
        min-width: 50%;

        @media (max-width: 768px) {
          width: 100%;
        }

        thead {
          tr {
            height: 32px;
            color: #ffffff;
            background-color: #3c3d3a;
            font-weight: bold;
          }
        }

        tr td {
          padding: 7px 10px;
        }

        tr {
          td: nth-child(3) {
            text-align: right;
          }
        }
      }
    }
  }
`;
